import { useState } from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

export const Header = () => {
    const [active, setActive] = useState(false)


    const onHamburgerClick = () => {
        setActive(!active)
    }

    const setActiveClass = () => {
        return active ? "active" : ""
    }


    return (
        <div className="Header-content">
            <div className='Header-title'>Jonas Hallböök</div>

            <div className='Hamburger'>
                <button className='toggle-button' onClick={onHamburgerClick}><FontAwesomeIcon icon={faBars}/></button>
            </div>

            <nav className={'navbar-links ' + setActiveClass()} >
                <Link to='/' onClick={onHamburgerClick}>Home</Link>
                <Link to='/projects' onClick={onHamburgerClick}>Projects</Link>
                <Link to='/skills' onClick={onHamburgerClick}>Skills</Link>
            </nav>
        </div>
    )
}

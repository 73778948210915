import { Footer } from '../../header/Footer'
import { Skillboxes } from './components/Skillboxes'
import { OSBoxes } from './components/OSBoxes'



export const Skills = () => {

    return (
        <div className='Skills'>

            <div className="Skills-container">

                <div className="Skill-title">
                    <h2>Skills</h2>
                </div>

                <div className="SKills-OSBoxes">
                    <OSBoxes />
                </div>

                <div className="Skills-languages">
                    <Skillboxes />
                    {/* <Skillboxes /> */}
                </div>

                <div className="Skill-outro">
                    <h2>Outro</h2>
                </div>


            </div>

            <Footer />
        </div>
    )
}

import skilldata from "../skill-data/languages-data.json"

interface ISkillbox {
    src: string
    level: number
    frameworks: any
}
const SkillBox = ({ src, level, frameworks }: ISkillbox) => {
    const width = 10 * level
    const style = {
        width: width + "%"
    }

    return (

        <div className="Skillbox-container">
            <div className='Skillbox'>
                <img src={src} alt=""/>
                <div className="Skillbox-meter">
                    <div className="Skillbar" style={style} ></div>
                </div>

            </div>
            <SkillBoxPopup frameworks={frameworks} />
        </div>

    )
}

interface IPopupBox {
    src: string
    level: number
}
const PopupBox = ({ level, src }: IPopupBox) => {

    const width = 10 * level
    const style = {
        width: width + "%"
    }

    return (
        <div className="Popup-box">
            <img src={src} alt=""/>
            <div className="Skillbox-meter">
                <div className="Skillbar" style={style} ></div>
            </div>
        </div>
    )
}

interface ISkillBoxPopup {
    frameworks: []
}
const SkillBoxPopup = ({ frameworks }: ISkillBoxPopup) => {

    const boxes: any = []
    if (frameworks === undefined) {
        return (
            <></>
        )
    }

    frameworks.forEach(({ src, level }) => {
        boxes.push(
            <PopupBox src={src} level={level} />
        )
    })

    return (
        <div className="Skillbox-popup">
            {boxes}
        </div>
    )

}

export const Skillboxes = () => {
    const boxes: any = []
    skilldata.forEach(({ src, level, frameworks }) => {
        boxes.push(
            <SkillBox src={src} level={level} frameworks={frameworks} />
        )
    })
    return (
        <div className='Skillboxlist'>
            {boxes}
        </div>
    )
}
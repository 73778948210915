import { useEffect } from 'react'
import { ProjectCardContainer } from './components/ProjectCardContainer';
import projectDataFile from "./project-data/projectdata.json";
import codeImg from "../../assets/code-img.png"
import { Footer } from '../../header/Footer';

export const Projects = () => {

    const ProjectCardList = () => {
        const projectCards: any = []
        let key = 0
        projectDataFile.forEach(({ title, subtitle }) => {
            projectCards.push(
                <ProjectCardContainer subtitle={subtitle} title={title} key={key} />
            )
            key++
        })
        return (
            projectCards
        )
    }

    useEffect(() => {
        const element = document.getElementById("p-wrapper")
        console.log(
            element
        )

        if (element) {
            console.log(element.scrollHeight)
            console.log(element.clientHeight)
        }


    }, [])

    return (
        <div className='Projects'>
            <div className='para-wrapper' id='p-wrapper'>

                <div className='Project-intro intro-top'>
                    <h2>Projects</h2>
                    <div className='intro-top-triangle'></div>
                </div>


                <div className="Project-list" >
                    <ProjectCardList />
                </div>

                <div className='para-group para' id='group-1'>
                    <div className='para-layer'>
                        <img src={codeImg} alt="Blurred code" />
                        <div className='Project-bg-blur'></div>
                    </div>
                </div>
                
                <div className='Project-intro intro-bottom'>
                    <h2>Projects</h2>
                    <div className='intro-bottom-triangle'></div>
                </div>

                <Footer />

            </div>

   
            {/* <div className='Project-bg'>
                <img src={codeImg} alt="Image of code" className='Project-code-img' />
                <div className='Project-bg-blur'></div>
            </div> */}

        </div>
    )
}

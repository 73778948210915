interface IOsBox {
    src: string
}

const OSBox = ({ src }: IOsBox) => {
    return (
        <div className="OSBox">
            <img src={src} alt=""/>
            <div className='OSBox-meter'>
                {/* <div className="outer">
                    <div className="inner"></div>
                </div> */}
                <div className="circle">
                    <div className="innercircle"></div>
                </div>
            </div>
        </div>
    )
}


export const OSBoxes = () => {
    return (
        <div className='OSBoxes'>
            <OSBox src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/windows8/windows8-original.svg' />
            <OSBox src="https://cdn.jsdelivr.net/gh/devicons/devicon/icons/linux/linux-original.svg" />
            <OSBox src='https://cdn.jsdelivr.net/gh/devicons/devicon/icons/apple/apple-original.svg' />
        </div>
    )

}


import React from 'react'
import footerData from "./footerdata.json";

export const Footer = () => {


    interface IFooterInfo {
        right: string,
        left: string
    }
    const FooterInfo = ({left, right} : IFooterInfo) => {
        return (
            <div className='Footer-info'>
                <>
                    <div className='Footer-text'>{left}</div>
                    <div className='Footer-dot'></div>
                    <div className='Footer-text'>{right}</div>
                </>
            </div>
        )
    }


    const FooterList = () => {
        const footerInfo: any = []
        for (let i = 0; i < 5; i++) {

        }

        footerData.forEach(({right, left}) => {
            footerInfo.push(
                <FooterInfo left={right} right={left} />
            )
        })
        return footerInfo

    }

    return (
        <div className='Footer'>
            <div className='Footer-triangle-top'></div>
            <div className='Footer-center'>
                <FooterList />
            </div>
        </div>
    )
}

import { IProjectCard, ProjectCard } from './ProjectCard'

interface IProjectCardContainer {

}


export const ProjectCardContainer = ({ subtitle, title }: IProjectCardContainer & IProjectCard) => {

    // function getRandomInt(min: number, max: number) {
    //     min = Math.ceil(min);
    //     max = Math.floor(max);
    //     return Math.floor(Math.random() * (max - min) + min);
    // }

    // const generateStyleTop = () => {
    //     const random = getRandomInt(2, 5)
    //     const style = {
    //         borderTop: random + "rem solid transparent",
    //         top: -random + "rem"
    //     }
    //     return style
    // }

    // const generateStyleBottom = () => {
    //     const random = getRandomInt(5, 10)
    //     const style = {
    //         borderTop: random + "rem solid var(--PCC-color)",
    //         bottom: -random + "rem"
    //     }
    //     return style
    // }

    return (
        <div className='Project-cardcontainer'>
            <div className='PCC-triangle-top' ></div>
            <ProjectCard subtitle={subtitle} title={title} />
            <div className='PCC-triangle-bottom' ></div>
        </div>
    )
}


import softwareImg from "../../../assets/software-img.webp"



export interface IProjectCard {
    title: string
    subtitle: string
}

export const ProjectCard = ({ subtitle, title }: IProjectCard) => {
    return (
        <div className='Project-card'>

            <img className='PC-bg-img' src={softwareImg} alt="random" />

            <div className='PC-left'>
                <div className="PC-left-text">
                    <h2 className="PC-title">{title}</h2>
                    <h3 className='PC-subtitle'>{subtitle}</h3>
                    {/* <a href="https://github.com/">Github</a><FontAwesomeIcon icon={faGithub} /> */}
                </div>
                <div className='PC-triangle'></div>
                <div className='PC-triangle-border'></div>

            </div>

            <div className='PC-right'>

                {/* <div className='PC-slider'></div> */}
                <div className='PC-right-square'>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fuga fugiat necessitatibus nam laboriosam esse sed eaque ad mollitia a delectus non corrupti, iste quam rerum suscipit quae laborum quia?</p>
                    <h3 className='PC-info-button'>Check it out</h3>

                </div>

                <div className='PC-right-blur'>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur fuga fugiat necessitatibus nam laboriosam esse sed eaque ad mollitia a delectus non corrupti, iste quam rerum suscipit quae laborum quia?</p>
                </div>


            </div>








        </div>
    )
}

import './style/app.scss';
import './style/header.scss';
import './style/footer.scss';
import './style/home.scss';
import './style/projects.scss';
import './style/project-card.scss';
import './style/project-cardcontainer.scss';
import './style/skills.scss';
import './style/skillboxes.scss';
import './style/skills-osboxes.scss';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Header } from './header/Header';
import { Home } from './pages/home/Home';
import { Projects } from './pages/projects/Projects';
import { Skills } from './pages/skills/Skills';



function App() {


    return (
        <Router>
            <div className="App" >
                <Header />
                <div className="Content" >
                    <Routes>
                        <Route path='/' element={<Home />}></Route>
                        <Route path='/projects' element={<Projects />}></Route>
                        <Route path='/skills' element={<Skills />}></Route>
                    </Routes>

                </div>

            </div>
        </Router>
    );
}

export default App;

export const Home = () => {

    return (
        <div className='Home'>

            <div className='Home-introsection' >
                {/* <div className='Home-introsection-img'>
                    <img className='Home-cv-img' src={Jonas} alt="A picture of myself"  onLoad={getHeightImage}/>
                </div> */}
                <div className='Home-introsection-info'>
                    <h2>Here I am</h2>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt deserunt ducimus provident eius labore accusantium laboriosam asperiores cumque amet officiis. </p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt deserunt ducimus provident eius labore accusantium laboriosam asperiores cumque amet officiis. </p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt deserunt ducimus provident eius labore accusantium laboriosam asperiores cumque amet officiis. </p>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sunt deserunt ducimus provident eius labore accusantium laboriosam asperiores cumque amet officiis. </p>
                </div>
            </div>



        </div>
    )
}
